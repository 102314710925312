@import 'mixins';
@import 'fonts';
@import 'fontawesome';
@import 'variables';
@import '~bootstrap/scss/bootstrap';


main {
	padding-top: 90px;
}

nav {
    -webkit-box-shadow: 0 1px 0.625rem rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 1px 0.625rem rgba(0, 0, 0, 0.4);
           box-shadow: 0 1px 0.625rem rgba(0, 0, 0, 0.4);
}

.section-ai {
    padding: 4rem 0;
    @include media-breakpoint-down(md) { padding: 2rem 0 }
}

.overlay {
    background: url(../img/random.webp) repeat rgba(0, 0, 0, 0.3);
}

.overlay-white {
    background: url(../img/brickwall.webp) repeat rgba(0, 0, 0, 0.3);
}

.site-head {
	margin-bottom: 1rem;

	.over {
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    z-index: 2;
	    opacity: .4;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
	    background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, $black), color-stop(1, $principal));
	}

	.title {
	    font-style: normal;
	    font-weight: 700;
	    font-size: 3.75rem;
	    line-height: 1.1875;
	    letter-spacing: 0em;
	    padding-left: 2rem;

	    &:before {
		    content: "";
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    width: 0.375rem;
		    background-color: $principal;
		}
	}
}

hr.span {
    border: 0;
    width: 20%;
    height: 1px;
    background-image: -moz-gradient(linear, 100% 0%, 0% 0%, from($terciario), to($terciario), color-stop(.5, $principal));
    background-image: -webkit-gradient(linear, 100% 0%, 0% 0%, from($terciario), to($terciario), color-stop(.5, $principal));
    background-image: linear-gradient(left, $terciario, $principal, $terciario);
}

.bg-grey-lighter {
    background: $terciario;
}

#about {
    position: relative;
    background-image: url(../img/about.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 18.75rem 0 20px;
}

#services {
    position: relative;
    background-image: url(../img/services.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 18.75rem 0 20px;
}

#contact {
    position: relative;
    background-image: url(../img/contact.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 18.75rem 0 20px;
}

.bgpattern {
    background-image: url(../img/bgpattern.webp);
    background-position: bottom center;
    background-repeat: repeat-x;
    padding: 60px 0px 60px 0px;
}

.bgpattern-revert {
    background-image: url(../img/bgpattern-revert.webp);
    background-position: left top;
    background-repeat: repeat-x;
    padding: 60px 0px 60px 0px;
}

.ai-image {
    &:hover {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    }
}

#values {
    background-image: url(../img/business.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem 0rem;
}

.bg-wall {
    background: url(../img/brickwall.webp) repeat rgba(0, 0, 0, 0.5);
}

.ai-services-area {
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
    background: -webkit-gradient(linear, left bottom, left top, from(#d5fefd), color-stop(#e1fdff), color-stop(#effdff), color-stop(#fafdff), to($white));
    background: linear-gradient(to top, #d5fefd, #e1fdff, #effdff, #fafdff, $white);
}

.carousel-item {
    @include media-breakpoint-down(md) { height: 400px }
}

.card-ai {
    background-color: $gray-900;
    &:hover {
        cursor: pointer;
        background-color: $principal;
    }
}